import Plyr from 'plyr';

export default class VideoPlayer {
  constructor(el) {
    this.el = el;
    
    this.playBtn = el.querySelector('.js-videoPlayerButton');

    const plyrEl = el.querySelector('.js-videoPlayerPlyr');
    this.plyr = new Plyr(plyrEl); // eslint-disable-line

    this.playBtn.addEventListener('click', () => {
      this.el.classList.add('playing');
      this.plyr.play();
    });
  }
}
