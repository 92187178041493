export default function nav() {
  //Vars
  const navMenu = document.querySelector('.nav-menu-mob');
  const mobileMenuBtn = document.querySelector('.nav-burger');
  const navBarLogo = document.querySelector('.nav-bar-logo');

  let toggleClass = 'inactive';

  //Mobile menu
  mobileMenuBtn.onclick = () => {
    toggleClass = toggleClass == 'inactive' ? 'active' : 'inactive';
    mobileMenuBtn.classList.remove('active', 'inactive');
    mobileMenuBtn.classList.add(toggleClass);
    navMenu.classList.toggle('open');
    navBarLogo.classList.remove('hidden');
  };
}
