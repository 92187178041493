import BackToTop from '../components/BackToTop';
import VideoPlayer from '../components/VideoPlayer';
import carousel from '../components/carousel';
import nav from '../components/nav';
import plyr from '../plugins/plyr';

// Elements
const videoEmbedEls = document.querySelectorAll('.js-videoEmbed');
const backToTopEl = document.querySelector('#js-backToTop');

export default {
  init() {
    plyr();
    nav();
    carousel();
    videoEmbedEls.forEach((el) => {
      new VideoPlayer(el);
    });
    if (backToTopEl) {
      new BackToTop(backToTopEl);
    }
  },
  finalize() {},
};
