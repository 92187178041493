import Swiper, { Navigation, Autoplay, Pagination } from 'swiper';

export default function carousel() {
  new Swiper('.swiper-card', {
    loop: true,
    modules: [Navigation],
    allowTouchMove: false,
    breakpoints: {
      768: {
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  new Swiper('.swiper-quotes', {
    loop: true,
    modules: [Navigation, Autoplay, Pagination],
    autoplay: {
      delay: 7000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        spaceBetween: 30,
      },
    },
    pagination: {
      el: '.pull-quote-logos',
      clickable: true,
      renderBullet: function (index, className) {
        const slide = document.getElementById('slide-' + index);
        return '<div class="ratio-16-9 ' + className +'"><img src="' + slide.dataset.logo +'" alt=""></div>';
      },
    },
  });
}
